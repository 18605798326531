var exports = {};
var prop = "_erd";

function initState(element) {
  element[prop] = {};
  return getState(element);
}

function getState(element) {
  return element[prop];
}

function cleanState(element) {
  delete element[prop];
}

exports = {
  initState: initState,
  getState: getState,
  cleanState: cleanState
};
export default exports;