var exports = {};

exports = function (options) {
  var idGenerator = options.idGenerator;
  var getState = options.stateHandler.getState;
  /**
   * Gets the resize detector id of the element.
   * @public
   * @param {element} element The target element to get the id of.
   * @returns {string|number|null} The id of the element. Null if it has no id.
   */

  function getId(element) {
    var state = getState(element);

    if (state && state.id !== undefined) {
      return state.id;
    }

    return null;
  }
  /**
   * Sets the resize detector id of the element. Requires the element to have a resize detector state initialized.
   * @public
   * @param {element} element The target element to set the id of.
   * @returns {string|number|null} The id of the element.
   */


  function setId(element) {
    var state = getState(element);

    if (!state) {
      throw new Error("setId required the element to have a resize detection state.");
    }

    var id = idGenerator.generate();
    state.id = id;
    return id;
  }

  return {
    get: getId,
    set: setId
  };
};

export default exports;