var exports = {};

exports = function (idHandler) {
  var eventListeners = {};
  /**
   * Gets all listeners for the given element.
   * @public
   * @param {element} element The element to get all listeners for.
   * @returns All listeners for the given element.
   */

  function getListeners(element) {
    var id = idHandler.get(element);

    if (id === undefined) {
      return [];
    }

    return eventListeners[id] || [];
  }
  /**
   * Stores the given listener for the given element. Will not actually add the listener to the element.
   * @public
   * @param {element} element The element that should have the listener added.
   * @param {function} listener The callback that the element has added.
   */


  function addListener(element, listener) {
    var id = idHandler.get(element);

    if (!eventListeners[id]) {
      eventListeners[id] = [];
    }

    eventListeners[id].push(listener);
  }

  function removeListener(element, listener) {
    var listeners = getListeners(element);

    for (var i = 0, len = listeners.length; i < len; ++i) {
      if (listeners[i] === listener) {
        listeners.splice(i, 1);
        break;
      }
    }
  }

  function removeAllListeners(element) {
    var listeners = getListeners(element);

    if (!listeners) {
      return;
    }

    listeners.length = 0;
  }

  return {
    get: getListeners,
    add: addListener,
    removeListener: removeListener,
    removeAllListeners: removeAllListeners
  };
};

export default exports;