var exports = {};

exports = function (options) {
  var getState = options.stateHandler.getState;
  /**
   * Tells if the element has been made detectable and ready to be listened for resize events.
   * @public
   * @param {element} The element to check.
   * @returns {boolean} True or false depending on if the element is detectable or not.
   */

  function isDetectable(element) {
    var state = getState(element);
    return state && !!state.isDetectable;
  }
  /**
   * Marks the element that it has been made detectable and ready to be listened for resize events.
   * @public
   * @param {element} The element to mark.
   */


  function markAsDetectable(element) {
    getState(element).isDetectable = true;
  }
  /**
   * Tells if the element is busy or not.
   * @public
   * @param {element} The element to check.
   * @returns {boolean} True or false depending on if the element is busy or not.
   */


  function isBusy(element) {
    return !!getState(element).busy;
  }
  /**
   * Marks the object is busy and should not be made detectable.
   * @public
   * @param {element} element The element to mark.
   * @param {boolean} busy If the element is busy or not.
   */


  function markBusy(element, busy) {
    getState(element).busy = !!busy;
  }

  return {
    isDetectable: isDetectable,
    markAsDetectable: markAsDetectable,
    isBusy: isBusy,
    markBusy: markBusy
  };
};

export default exports;